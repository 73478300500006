.path {

    &__wrapper {
    
        display: flex;
        list-style-type: none;
    
    }

    &__item {
    
        color: $color-blue;

        &:after {
        
            content: '\\';
            margin-left: 3px;
            margin-right: 3px;

        }

        &:last-child {
        
            &:after {
            
                content: '';
            
            }
        
        }
    
    }

    &__link {
    
        color: $color-blue;
        text-decoration: none;
        transition: color .6s ease;

        &:visited {
        
            color: $color-blue;
        
        }

        &:hover {
        
            color: $color-orange;
        
        }
    
    }

}
