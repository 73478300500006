.body {

    margin: 0;
    padding: 0;
    font-family: $font-default;
    background-color: #FFF;

}

.main-page__text > p > a {

    color: #0097d8;

    &:visited {

        color: #0097d8;

    }
}

.main-goals a {

    color: #0097d8;

    &:visited {

        color: #0097d8;

    }
}
