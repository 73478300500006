.pager {

    display: flex;
    justify-content: center;
    margin-top: 30px;

    &__pages {

        display: flex;
        background-color: #fafafa;

    }

    &__page {

        padding: 10px;
        background-color: transparent;
        text-decoration: none;
        color: $color-blue;
        transition: .6s ease;
        cursor: pointer;

        &_active {

            color: $color-orange;

        }

        &_prev,&_next {

            .cls-1 {

                transition: .6s ease;

            }

            &:hover {

                .cls-1 {

                    stroke: $color-orange;
                    transition: .6s ease;

                }
            }
        }

        &_next {

            transform: rotateY(180deg);

        }

        &:hover {

            color: $color-orange;
            transition: .6s ease;

        }
    }

}
