.search {

    margin-bottom: 20px;

    &__container {

        width: 100%;
        height: 100%;

    }

    &__wrapper {

        display: flex;
        flex-direction: column;

    }

}

.search-line {

    &__reset {

        color: #0097d8;

        &:visited {

            color: #0097d8;

        }

    }
}

.search-result {

    display: flex;
    flex-direction: column;

    &__item {

        margin-bottom: 18px;
        color: #0097d8;

        &:visited {

            color: #0097d8;

        }

    }

}
