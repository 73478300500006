.popup-link__wrapper {
    position: relative;
}

.popup-link {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    text-decoration: none;
    color: #472929;

    &:hover {
        color: #5e1e1e;
    }
}
