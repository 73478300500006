.menu-wrapper {

    display: flex;
    width: 100%;
    max-height: 40px;
    background-color: $color-blue;

    @media screen and (max-width: 1156px) {

        display: none;
        cursor: pointer;

    }

    &_overflow-unset {

        overflow: unset !important;

    }

    &_mobile {

        background-color: $color-blue;
        position: relative;
        min-height: 40px;
        display: none;


        @media screen and (max-width: 1156px) {

            display: flex; 

        }

        &:after {

            position: absolute;
            top: calc(50% - 12px);
            left: 18px;
            content: '';
            width: 24px;
            height: 24px;
            background: url('/images/icons/burger.svg')no-repeat center;

        }

        .menu_level_0 {

            top: 100%;
            position: absolute;
            height: unset;
            background-color: $color-orange;
            flex-direction: column;
            align-items: flex-start;
            z-index: 10;
            box-sizing: border-box;
            max-height: 0;
            overflow: hidden;
            transition: max-height .6s ease;

            &_active {

                max-height: 100vh;
                padding: 14px 18px;
                transition: max-height .6s ease;
                overflow: auto;

            }

        }

        .menu_level_2 {

            padding-left: 40px;
            box-sizing: border-box;
            position: unset;
            max-width: 100%;
            max-height: 0;
            transition: 1s ease;

        }

        .menu__item_level_0 {

            width: 100%;
            box-shadow: unset;

        }

        .menu__link {
            
            border: none;
            background-color: transparent;

            &:hover {

                background-color: #FFF;
                color: $color-orange;

            }

        }

        .menu__link_level_0 {

            width: 100%;
            background-color: $color-orange;
            box-shadow: unset;


        }

        .menu_level_1 {

            padding-left: 18px;
            box-sizing: border-box;       
            position: unset;

        }

        .menu__item_level_1 {

            width: 100%;

            &:hover {

                .menu_level_2 {

                    max-height: 100vh;
                    transition: 1s ease;

                }

            }

        }

        .menu__link_level_1 {

            width: 100%;
            background-color: $color-orange;
            box-shadow: unset;

        }

        .menu-burger {

            display: none;

        }

    }

    &_footer {

        background-color: transparent;

        .menu__link {

            background-color: transparent;
            color: $color-black;
            border: none;

            &_burger {

                color: #FFF;

            }

        } 

        .menu__item {

            border: none;

        }

        .menu_level_1 {

            top: unset;
            bottom: 100%;

        }

        .menu_burger {

            top: unset;
            bottom: 100%;

        }

        .menu-burger {

            //background: url(/images/icons/burger-transparent.svg) no-repeat center;
            display: flex;
            justify-content: center;
            align-items: center;

        }

    }

}

.menu {

    font-family: $font-kurale;
    display: flex;
    width: 100%;
    padding: 0;
    list-style-type: none;

    &_level_0 {

        height: 100%;
        margin: 0;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        transition: 1s ease;

    }

    &_level_1 {

        flex-direction: column;
        position: absolute;
        top: 100%;
        height: unset; 
        z-index: 10;

    }

    &_level_2 {

        position: absolute;
        left: 100%;
        top: 0;
        flex-direction: column;
        list-style-type: none;
        max-width: 0;
        overflow: hidden; 
        transition: 1s ease;

    }

    &_burger {

        overflow: hidden;
        position: absolute;
        flex-direction: column;
        height: auto;
        top: 100%;
        right: 0;
        width: auto;
        min-width: 250px;
        background-color: #F46C56;
        overflow: hidden;
        max-height: 0;
        transition: 1s ease;

        .menu__item {

            box-shadow: unset;

        }

        .menu__link {
            border: 1px solid $color-orange;

            &:hover {

                background-color: #FFF !important;
                color: $color-orange;
                border: 1px solid $color-orange;

            }

        }
    }

    &__item {

        position: relative;

        &_level_0 {

            height: 100%;
            display: flex;
            flex-direction: column;
            list-style-type: none;
            box-shadow: 0px 4px 6px $color-box;
            transition: 1s ease;


            &:hover .menu__item_level_1 {

                max-height: 100vh;
                transition: 1s ease;
                overflow: visible;

            }

        }

        &_level_1 {

            overflow: hidden;
            max-height: 0;
            width: 285px;
            transition: 1s ease;

            &:hover {

                .menu_level_2 {

                    max-width: 100vh;
                    transition: 1s ease;

                }

            }

        }
    }

    &__link {
        height: 100%;
        padding: 8px 24px;
        box-sizing: border-box;
        background-color: $color-blue;
        border: 1px solid $color-blue;
        color: #FFF;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: .6s ease;

        &:hover {

            background-color: #FFF;
            color: $color-blue;
            transition: .6s ease;

        }

        &_burger {

            color: #FFF;
            border: none;

        }

        &_level_0 {

            white-space: nowrap;
            color: #FFF;

        }

    }

}

.menu-burger {

    margin-left: 10px;
    width: 70px;
    // background: url('/images/icons/burger.svg') no-repeat center;
    // background-size: 40% 40%;
    // background-color: $color-orange;
    cursor: pointer;
    position: relative;
    transition: 1s ease;
    z-index: 1;

    &:hover .menu_burger {

        max-height: 100vh;
        transition: 1s ease;

    }

    .menu__link.menu__link_level_0 {

        border-bottom: none;
        background-color: #F46C56;

    }

}


