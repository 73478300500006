/* autoprefixer grid: autoplace */

.news {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(270px,1fr));
    gap: 30px 25px;

}

.news-item {

    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;

    &__image {
    
        width: 100%;
        height: 180px;
        background: url('/images/icons/news.svg') no-repeat center / contain;
        margin-bottom: 10px;
    
    }

    &__date {
    
        font-size: 12px;
        font-weight: 300;
        color: #808080;
        margin-bottom: 10px;
    
    }

    &__title {
    
        font-size: 18px;
        margin-bottom: 10px;
    
    }

    &__announce {
    
        font-weight: 300;
    
    }
}
