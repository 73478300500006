.title {

    margin: 0;
    padding: 0;
    color: $color-black;
    font-weight: bold;
    transition: .6s ease;

    &_header-first {

        font-size: 17px;
        font-family: $font-kurale;
        color: #005276;
        font-weight: 400;
        margin-bottom: 11px;

    }

    &_header-second {

        font-size: 24px;
        font-weight: 700;
        font-family: $font-kurale;
        color: #7B4626;

    }

    &_h1 {

        font-size: 20px;
        line-height: 35px;
        font-weight: 400;

        @media screen and (max-width: 656px) {

            font-size: 16px;
            line-height: 15px;
        }

        @media screen and (max-width: 456px) {

            font-size: 12px;

        }
    }

    &_h2 {

        font-size: 18px;
        line-height: 26px;

        @media screen and (max-width: 656px) {

            font-size: 16px;
            line-height: 15px;
        }

        @media screen and (max-width: 456px) {

            font-size: 12px;

        }   
    }

    &_white {

        color: $color-white;

    }

    &_mb10 {

        margin-bottom: 10px;

    }

    &_mb30 {

        margin-bottom: 30px;

    }

}
