.header {

    margin-bottom: 80px;

    @media screen and (max-width: 856px) {

        margin-bottom: 40px;

    }

    &__top-line {

        margin-bottom: 22px;
        width: 100%;
        max-width: 1920px;
        height: 40px;
        box-shadow: 0px 4px 6px $color-box;
        background-color: #FAFAFA;
        display: flex;
        justify-content: center;
        padding: 0 22px;
        box-sizing: border-box;

        @media screen and (max-width: 1156px) {

            margin-bottom: 0;

        }

    }

    &__main {

        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 22px;
        box-sizing: border-box;

    }

    &__side {

        padding: 64px 35px 25px 40px;
        display: flex;
        width: 100%;
        box-sizing: border-box;
        background: url('/images/header-background.jpg') no-repeat center/cover;

        @media screen and (max-width: 656px) {

            padding: 35px 35px 25px 35px;

        }
    }

    &__wrapper {

        width: 100%;
        max-width: 1137px;
        display: flex;
        justify-content: space-between;

        &_main {

            min-height: 316px;
            @media screen and (max-width: 656px) {

                min-height: unset;
                flex-direction: column;

            }
        }

    }

    &__item-wrapper {

        height: 100%;
        max-width: 470px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        &_header {

            justify-content: unset;
            max-width: 755px;

        }

        @media screen and (max-width: 656px) {

            padding-top: 32px;

        }
    }
}

.site-map {

    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    &:link {

        color: #000;

    }

    &:visited {

        color: #000;

    }

    &__text {

        font-size: 14px;

        @media screen and (max-width: 656px) {

            display: none;

        }
    }

    &__image {

        width: 30px;
        height: 30px;
        margin-right: 20px;

        background: url('/images/icons/globus.svg') no-repeat center;
        background-size: cover;

        @media screen and (max-width: 656px) {
            margin-right: 0;
        }
    }

}

.accessibility {

    display: flex;
    align-items: center;
    cursor: pointer;

    &__text {

        font-size: 14px;

        @media screen and (max-width: 656px) {

            display: none;

        }

    }

    &__image {

        width: 40px;
        height: 40px;
        margin-right: 20px;

        background: url('/images/icons/glasses.svg') no-repeat center;
        background-size: cover;

    }

}

.logo {

    margin-bottom: 5px;
    min-width: 305px;
    min-height: 316px;

    background: url('/images/icons/new-logo.svg') no-repeat center;
    background-size: contain;

    @media screen and (max-width: 656px) {

        min-width: 152px;
        min-height: 225px;
        margin-right: 5px;
        width: 100%;

    }

    // @media screen and (max-width: 656px) {

    //     min-width: 122px;
    //     min-height: 128px;

    // }

}

.search-form {

    position: relative;
    width: 100%;
    max-width: 267px;
    height: 40px;
    box-shadow: 0px 4px 6px $color-box;

    &_hidden {

        @media screen and (max-width: 1156px) {

            display: none;

        }

    }

    &_mobile {

        display: none;

        @media screen and (max-width: 1156px) {
            max-width: unset;
            display: flex;
            margin-bottom: 25px;
        }

    }

    &__input {

        width: 100%;
        height: 100%;
        padding: 10px 20px;
        margin: 0;
        box-sizing: border-box;
        font-size: 14px;   
        border: none;
        background-color: #FCFCFC;

    }

    &__button{
        position: absolute;
        right: 8%;
        top: 20%;
    }


    &:before {

        width: 24px;
        height: 24px;
        position: absolute;
        top: calc(50% - 12px);
        left: calc(100% - 44px);

        //background: url('/images/icons/search.svg') no-repeat center;
        content: '';

    }
}


