.main-page {

    margin-bottom: 76px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__text {

        font-size: 14px;

        a {

            color: #0097d8; 

        }
    }



}
