.main {

    width: 100%;
    display: flex;
    justify-content: center;

    &__wrapper {

        max-width: 1137px;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1200px) {
        
            padding: 0 36px;
        
        }

    }

}

.main-contacts {

    margin-bottom: 76px;
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 556px) {
    
        display: none;
    
    }

    &__image {
    
        margin-right: 10px;
        width: 100%;
        max-width: 285px;
        height: 100%;
        background: url('/images/contacts.jpg') no-repeat center;
        background-size: cover;
    
    }

    &__text-wrapper {
    
        margin-right: 10px;
        width: 100%;
        max-width: 470px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
    }

    &__title {
    
        margin-bottom: 5px;
        font-size: 15px;
        color: $color-black; 
        font-weight: 300;

        &_fs17 {
        
            font-size: 17px;
        
        }
    
    }

    &__contact {
    
        font-size: 15px;
        font-weight: 400;
    
        &_fs17 {
        
            font-size: 17px;
        
        }
    }

}

.useful-links {

    width: 100%;
    max-width: 270px;
    overflow: hidden;


    @media screen and (max-width: 856px) {
    
        display: none;
    
    }

    &__wrapper {

        display: flex;
    
    }

    &__slide {
    
        flex-shrink: 0;
    
    }

    &__toolbar {
    
       display: flex;
       justify-content: space-between;
    
    }

    &__arrows {
    
        max-width: 68px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    
    }

    &__title {
    
        font-size: 14px;
        font-weight: 600;

        &_fs16 {
        
            font-size: 16px;
        
        }
    
    }

    &__arrow {
    
        width: 24px;
        height: 24px;
        background-size: 6px 12px;
        box-shadow: 0px 4px 6px $color-box;
        cursor: pointer;
        background: url('/images/icons/arrow.svg') no-repeat center;
        background-color: #FAFAFA;

        &_next {
        
            transform: rotate(180deg);
        
        }
    
    }

    &__item {
    
        padding: 14px 10px 9px 17px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid #D7E7FA; 
        background-color: #FAFAFA;
        text-decoration: none;

        &:link {
        
            color: $color-black;
        
        }

        &:visited {
        
            color: $color-black;
        
        }
    
    }

    &__image {
    
        margin-right: 16px;
        width: 65px;
        min-width: 65px;
        height: 65px;
        background: url('/images/useful-links.png') no-repeat center;
        background-size: contain;
    
    }

}

.main-goals {

    margin-bottom: 60px;

    &__text {
    
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 300;

    }

    &__more {
    
        padding: 4px 13px 5px 11px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 300;
        color: $color-light-blue;
        border: 1px solid $color-light-blue;
        max-width: 115px;
        text-decoration: none;
    
    }

}

.main-news {

    margin-bottom: 80px;
    display: flex;
    flex-direction: column;

    /* autoprefixer grid: autoplace */

    &__wrapper {

        display: grid; 
        grid-template-columns: repeat(2, minmax(200px, 570px));
        grid-gap: 30px 30px;

        @media screen and (max-width: 650px) {

            grid-template-columns: repeat(1, minmax(200px, 570px));

        }


    }

    &__item {

        padding: 40px 96px 20px 40px;
        width: 100%;
        box-sizing: border-box;
        background-color: #FAFAFA;
        border: 1px solid #D7E7FA;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: .6s ease;
        text-decoration: none;

        
        @media screen and (max-width: 650px) {
        
            font-size: 14px;
        
        }
        

        &:link {
        
            color: #000;
        
        }

        &:visited {
        
            color: #000;
        
        }

        &:hover {
        
            background-color: $color-blue;
            color: #FFF;
            transition: .6s ease;

            .title {
            
                color: #FFF;
                transition: .6s ease;
            
            }

            .main-news__date {
            
                color: #FFF;
                transition: .6s ease;

            }
        
        }

    }

    &__text {
    
        @media screen and (max-width: 650px) {

            max-height: 71px;
            overflow: hidden; 

        }
    
    }

    &__date {

        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 300;
        color: $color-gray;
        transition: .6s ease;

    }


}
