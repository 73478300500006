.gallery {

    /* autoprefixer grid: autoplace */

    &__wrapper {

        margin-top: 69px;
        margin-bottom: 19px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        column-gap:  29px;
        row-gap:  13px;

        &_videos {

            grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));

        }

    }

    &__item {

        width: 100%;
        height: 100%;
        min-height: 300px;
        position: relative;
        display: flex;
        align-items: flex-end;
        text-decoration: none;
        overflow: hidden;
        transition: .6s ease;

        @media screen and (max-width: 756px) {

            min-height: 200px;

        }

        &:hover {

            .gallery__image {

                transform: scale(1.1);
                transition: .6s ease;

            }

        }

        .gallery__title {

            padding: 9px 25px;

        }

        &_photos {

            &:nth-child(1) {

                grid-area: 1 / 1 / 3 / 3;

                .gallery__title {

                    max-height: 72px;
                    padding: 20px 37px;

                }

                @media screen and (max-width: 1156px) {

                    grid-area: unset; 

                    .gallery__title {

                        max-height: unset;
                        padding: 9px 25px;

                    }
                }

            }

            &:nth-child(2) {

                grid-area: 1 / 3 / 3 / 5;

                .gallery__title {

                    max-height: 72px;
                    padding: 20px 37px;

                }

                @media screen and (max-width: 1156px) {

                    grid-area: unset; 

                    .gallery__title {

                        max-height: unset;
                        padding: 9px 25px;

                    }
                }
            }

        }

    }

    &__image {

        width: 100%;
        height: 100%;
        background: url('/images/icons/new-logo.svg') no-repeat center;
        background-size: contain;
        position: absolute;
        top: 0;
        z-index: -1;
        transition: .6s ease;

    }

    &__video {

        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;

        p {

            margin: 0;
            height: 100%;

            iframe {

                width: 100%;
                height: 100%;

            }

        }

    }

    &__title {

        width: 100%;
        background-color: rgba(0, 151, 216, 0.68);
        box-sizing: border-box;
        color: white;
        font-size: 24px;
        font-weight: 300px;
        max-height: 72px; 

        @media screen and (max-width: 456px) {

            font-size: 14px;
            max-height: unset;

        }

    }

}

.popup-window {

    //max-width: 700px !important;
    //max-height: 700px !important;
    width: 100%;
    height: 100%; 

    .content {
    
        padding: 20px;
        box-sizing: border-box;
    
    }

    .closer {

        z-index: 2;

    }

}

