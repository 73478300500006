.button {

    &_header {

        padding: 8px 23px;
        box-sizing: border-box;
        box-shadow: 0px 4px 6px $color-box;
        border: 1px solid #56CCF2; 
        font-size: 16px;
        background-color: #FCFCFC;
        cursor: pointer;
        width: max-content;
        text-decoration: none;

        &:link {

            color: #000;

        }

        &:visited {

            color: #000;

        }

        @media screen and (max-width: 656px) {

            padding: 8px 18px;
            font-size: 12px;
            width: auto;

        }


    }

    &_more {

        padding: 4px 13px 5px 11px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 300;
        color: $color-light-blue;
        border: 1px solid $color-light-blue;
        max-width: 115px;
        text-decoration: none;

    }

    &_white {

        color: $color-white;
        border: 1px solid $color-white;

    }

}
