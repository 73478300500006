.footer {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__top-line {

        width: 100%;
        height: 40px;
        box-shadow: 0px 4px 6px $color-box;
        background-color: #FAFAFA;
        display: flex;
        justify-content: center;

    }

    &__wrapper {

        width: 100%;
        max-width: 1137px;
        display: flex;
        justify-content: center;
    }

    &__main {
    
        display: flex;
        width: 100%;
    
    }

    &__map-wrapper {
    
        background-color: $color-blue;
        width: 100%;
        padding: 76px 85px 60px 85px;
        box-sizing: border-box;
    
        @media screen and (max-width: 1056px) {
        
            display: none;
        
        }
    }

    &__map {
    
        height: 220px;
        width: 400px;
        margin-bottom: 55px;
        box-shadow: 0px 4px 6px $color-box;
        overflow: hidden;
    
    }

    &__sputnik {
    
        width: 81px;
        height: 31px;
        background: url('/images/sputnik.png') no-repeat center;
        background-size: contain;
    
    }

}

.footer-contacts {

    min-height: 410px;
    height: 100%;
    width: 100%;
    padding: 44px 10px 56px 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1056px) {

        background-color: $color-blue;
        color: #FFF;

    }

    &__text-wrapper {

        width: 100%;
        max-width: 470px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 1056px) {

            color: #FFF;

        }

    }

    &__row {

        &_mb42 {

            margin-bottom: 42px;

        }

    }

    &__title {

        margin-bottom: 5px;
        font-size: 15px;
        color: $color-black; 
        font-weight: 300;

        @media screen and (max-width: 1056px) {

            color: #FFF;

        }

        &_fs17 {

            font-size: 17px;

        }

    }

    &__contact {

        font-size: 15px;
        font-weight: 400;

        &_fs17 {

            font-size: 17px;

        }
    }

}
